<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    :showFilter="false"
    :tableDefaultValue="tableDefaultValue"
  >
    <template v-slot:type="{ row }">
      {{
        row.type &&
        zadarmaCallsTypes.data.length &&
        zadarmaCallsTypes.data.find((item) => item.value === row.type)
          ? zadarmaCallsTypes.data.find((item) => item.value === row.type).name
          : ''
      }}
    </template>

    <template v-slot:status="{ row }">
      {{
        row.status &&
        zadarmaCallsStatuses.data.length &&
        zadarmaCallsStatuses.data.find((item) => item.value === row.status)
          ? zadarmaCallsStatuses.data.find((item) => item.value === row.status)
              .name
          : ''
      }}
    </template>

    <template v-slot:callFile="{ row }">
      <div v-show="+row.duration" class="editable-list__datatable-buttons">
        <button
          class="editable-list__datatable-button"
          @click="onDownloadCall(row.id)"
        >
          <sm-icon name="document-download" />
        </button>
      </div>
    </template>
  </sm-editable-list>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'SipCallsForTelemarketingTaskCurrentUser',

  components: {
    SmEditableList,
    SmIcon,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'SipCalls',
      tableCaption: 'Звонки по телемаркетингу',
      tableDefaultValue: {
        order: 'time',
        count: 10,
        skip: 0,
        orderDesc: true,
      },
      tableHeaders: [
        { text: 'Тип звонка', alias: 'type' },
        { text: 'Статус звонка', alias: 'status', order: 'status' },
        { text: 'Телефонный номер', alias: 'phone', order: 'phone' },
        { text: 'Время звонка', alias: 'time', order: 'time' },
        {
          text: 'Длительность звонка в секундах',
          alias: 'duration',
          order: 'duration',
        },
        { text: '', alias: 'callFile' },
      ],
      callTypes: [
        { value: 'outgoing', text: 'Исходящий' },
        { value: 'incoming', text: 'Входящий' },
      ],
      statusTypes: [
        { value: 'answer', text: 'Успешный звонок' },
        { value: 'noanswer', text: 'Без ответа' },
        { value: 'cancel', text: 'Отменён' },
        { value: 'busy', text: 'Занято' },
        { value: 'call failed', text: 'Не удался' },
      ],
    };
  },

  computed: {
    ...mapState({
      zadarmaCallsTypes: (state) => state.common.zadarmaCallsTypes,
      zadarmaCallsStatuses: (state) => state.common.zadarmaCallsStatuses,
    }),

    id() {
      return +this.$route.params.id;
    },

    phone() {
      const filter = JSON.parse(this.$route?.query?.filter);
      if (filter && filter.length) {
        return filter[0]?.value;
      }
      return '';
    },

    breadcrumbs() {
      return [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Задания по телемаркетингу для текущего пользователя',
          route: { name: 'TelemarketingTasksForCurrentUser' },
        },
        {
          text: `Редактирование задания по телемаркетингу для текущего пользователя №${this.id}`,
          route: {
            name: 'TelemarketingTaskForCurrentUserEdit',
            params: { id: this.id },
          },
        },
        {
          text: `Звонки по телемаркетингу для ${this.phone}`,
        },
      ];
    },
  },

  created() {
    this.getCommonList({ name: 'zadarmaCallsTypes' });
    this.getCommonList({ name: 'zadarmaCallsStatuses' });
  },

  methods: {
    ...mapActions({
      downloadFile: 'editableList/downloadFile',
      getCommonList: 'common/getCommonList',
    }),

    onDownloadCall(id) {
      this.downloadFile({
        name: this.controllerName,
        id,
        type: 'file',
      });
    },
  },
};
</script>
